<template>
  <div class="api-card">
    <!-- 顶部图 -->
    <div class="header-banner">
      <div class="container mx-auto title-box">
        <h1 class="banner-one-title">专业数字权益商品标准化接口、免费接入服务</h1>
        <div class="banner-two flex">
          <button class="btn">全品类</button>
          <button class="btn">标准化接口</button>
          <button class="btn">平台优惠资源</button>
        </div>
        <button class="btn-line">立即咨询</button>
      </div>
    </div>
    <!-- 运营服务保障 -->
    <div class="api-card-service">
      <div class="container mx-auto">
        <h2 class="three-leave-title">运营服务保障</h2>
        <div class="content grid grid-cols-3 gap-4">
          <div class="card flex justify-center align-center flex-col">
            <div class="box flex justify-center align-center">
              <img src="@/assets/img/api/api-shop.png" />
            </div>
            <p class="title">商品管理</p>
            <p class="desc">为api商户提供可以对接的商品类目、商品编号、商品面值及商品具体的使用流程等信息</p>
          </div>
          <div class="card flex justify-center align-center flex-col">
            <div class="box flex justify-center align-center">
              <img src="@/assets/img/api/api-order.png" />
            </div>
            <p class="title">订单管理</p>
            <p class="desc">提供订单明细查询、充值记录核对，订单导出等功能</p>
          </div>
          <div class="card flex justify-center align-center flex-col">
            <div class="box flex justify-center align-center">
              <img src="@/assets/img/api/api-config.png" />
            </div>
            <p class="title">应用配置</p>
            <p class="desc">密钥管理、账号绑定、IP白名单添加、参数配置</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 使用场景 -->
    <div class="api-card-uses">
      <div class="container mx-auto">
        <h2 class="three-leave-title">使用场景</h2>
        <p class="desc">
          针对拥有一定技术团队的企业，通过接入璐付充值API，用于手机App、PC客户端、微信公众号、小程序、支付宝生活号等多种场景，为企业自身用户提供在线充值服务，丰富服务内容，促进流量变现。
        </p>
        <div class="content grid grid-cols-5 gap-0">
          <div class="card">
            <img src="@/assets/img/api/api.png" />
          </div>
          <div class="step flex justify-center align-center">
            <img src="https://img.js.design/assets/img/607ba7b8d253563f2be8d04d.png" />
          </div>
          <div class="card flex align-center pc-phone">
            <img src="@/assets/img/api/pc-phone.png" />
          </div>
          <div class="step flex justify-center align-center">
            <img src="https://img.js.design/assets/img/607ba7b8d253563f2be8d04d.png" />
          </div>
          <div class="card">
            <img src="@/assets/img/api/money.png" />
          </div>
        </div>
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="product-advantages">
      <div class="container mx-auto">
        <h2 class="three-leave-title">产品优势</h2>
      </div>
      <div class="card">
        <div class="container mx-auto one-card flex align-center">
          <div class="left">
            <img src="@/assets/img/api/api-recharge.png" />
          </div>
          <div class="right flex flex-col justify-center">
            <div class="info-box">
              <h1 class="title">高效稳定低延迟的技术方案</h1>
              <p class="desc">
                100+专业技术团队打造，稳定高效，畅享低延迟高稳定解决方案，立志于为企业带来变革级全新体验，支持各种技术方案、多维度全链路场景，为企业降本增效。
              </p>
            </div>
          </div>
        </div>
        <div class="two-card">
          <div class="container mx-auto flex align-center">
            <div class="left flex flex-col justify-center">
              <div class="info-box">
                <h1 class="title">货源丰富，涵盖全品类</h1>
                <p class="desc">
                  1000+数字权益商品，全品类覆盖，可满足不同行业、场景需求。整合优质供应资源、品牌源头商品，全网价格最优。100%品牌产品，100%正品保障，十大品类，涵盖国内外知名品牌10W+SKU，保障每一款商品品质和优质服务。
                </p>
              </div>
            </div>
            <div class="right">
              <img src="@/assets/img/api/api-equarity.png" />
            </div>
          </div>
        </div>
        <div class="container mx-auto one-card flex align-center">
          <div class="left">
            <img src="@/assets/img/api/api-money.png" />
          </div>
          <div class="right flex flex-col justify-center">
            <div class="info-box">
              <h1 class="title">降本增效，助力企业业务快速增长</h1>
              <p class="desc">
                提供全面丰富的管理能力，订单管理、财务管理让订单、往来账一目了然，减轻财务压力，降低企业管理成本；丰富多样的商品牢牢锁定用户，不断为用户带来全新体验，有效提升用户忠诚度，促进企业业务快速增长，为企业增收盈利。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// components

export default {
  data() {
    return {
      columnList: [{}, {}, {}, {}]
    };
  },
  mounted() {
    new this.$wow.WOW().init({
      //新建实列
      boxClass: 'wow', //class名字
      animateClass: 'animate__animated', //animateclass动画库类名,和当前animate动画库决定
      offset: 100, //到元素距离触发动画（当默认为0）
      mobile: true, //在移动设备上触发动画（默认为true）
      live: true //对异步加载的内容进行操作（默认为true）
    });
  }
};
</script>

<style lang="less" scoped>
.api-card {
  margin-bottom: 100px;
  .header-banner {
    height: 483px;
    width: 100%;
    background-image: url('../../assets/img/api/api-logo.png');
    // background-size: 100% 100%;
    background-position: center;
    background-size: center center;
    .title-box {
      padding-top: 99px;
      .banner-one-title,
      .banner-two-title {
        margin-left: 45px;
      }
      .banner-two-title {
        margin: 10px 45px;
      }
      .banner-two {
        margin: 20px 45px 0 45px;
        .btn {
          padding: 0 10px;
          height: 34px;
          border: 1px solid @lupay-text-color-theme;
          border-radius: 17px;
          margin-right: 10px;
          color: @lupay-text-color-theme;
        }
      }
      .btn-line {
        margin-top: 80px;
        margin-left: 49px;
        border-radius: 6px;
        position: relative;
      }
      .btn-line:hover::after {
        content: '';
        display: block;
        width: 160px;
        height: 160px;
        top: 80px;
        left: -1px;
        position: absolute;
        background-image: url('../../assets/img/lupaywechat.png');
        background-size: 100% 100%;
        padding: 2px;
        border: 1px solid #f4f4f4;
        opacity: 1;
        transition-duration: 300ms;
      }
    }
  }
  &-service {
    margin-top: 77px;
    .three-leave-title {
      color: rgba(0, 0, 0, 0.8);
      font-size: 22px;
      margin-left: 49px;
      //   font-weight: 400;
    }
    .content {
      margin-top: 47px;
      .card {
        width: 384px;
        height: 384px;
        background: rgba(224, 240, 255, 0.2);
        border-radius: 12px;
        .box {
          width: 111px;
          height: 111px;
          border-radius: 50%;
        }
        .title {
          color: rgba(0, 0, 0, 0.8);
          font-size: 16px;
          margin-top: 43px;
          margin-bottom: 17px;
        }
        .desc {
          width: 237px;
          height: 38px;
          color: rgba(0, 0, 0, 0.6);
          font-size: 14px;
          line-height: 22px;
        }
      }
      .card:hover {
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 3px 10px rgba(34, 78, 219, 0.1);
      }
    }
  }
  &-uses {
    height: 637px;
    width: 100%;
    background: rgba(224, 240, 255, 0.2);
    margin-top: 80px;
    padding-top: 45px;
    .three-leave-title {
      color: rgba(0, 0, 0, 0.8);
      font-size: 22px;
      margin-left: 49px;
    }
    .desc {
      margin: 43px 0 0 49px;
      height: 21px;
      color: rgba(0, 0, 0, 0.8);
      font-size: 14px;
    }
    .content {
      margin-top: 128px;
      .card {
        width: 248px;
        height: 248px;
      }
      .step {
        img {
          width: 85px;
          height: 85px;
        }
      }
    }
  }
  .product-advantages {
    padding-top: 77px;
    .three-leave-title {
      color: rgba(0, 0, 0, 0.8);
      font-size: 22px;
      margin-left: 49px;
    }
    .card {
      .one-card {
        height: 622px;
        .left {
          width: 55%;
        }
      }
      .title {
        color: @lupay-text-color-theme;
        font-size: 22px;
        position: relative;
      }
      .title::before {
        content: '';
        position: absolute;
        display: block;
        width: 10px;
        height: 10px;
        background: rgba(61, 238, 228, 1);
        left: -15px;
        top: 14px;
        border-radius: 50%;
      }
      .desc {
        color: rgba(0, 0, 0, 0.8);
        font-size: 14px;
        margin-top: 10px;
        max-width: 500px;
      }
      .two-card {
        height: 540px;
        background: rgba(224, 240, 255, 0.2);
        .left {
          width: 50%;
          height: 100%;
        }
        .right {
          padding-top: 80px;
        }
      }
      .info-box {
        margin-bottom: 70px;
      }
    }
  }
  .fore-card {
    margin-top: 40px;
  }
}
</style>
